import React from "react"
import { LocaleContext } from "../components/layout"
import useTranslations from "../components/use-translations"

import {
  INDEX_PAGEKEYS_ABOUT,
  INDEX_PAGEKEYS_CONTACT
} from "../data/assets"

import SEO from "../components/seo"

import { ProGallery, ProGalleryRenderer } from 'pro-gallery'
import 'pro-gallery/dist/statics/main.css';

import { items } from '../data/items'
import useWindowDimensions from "../hooks/window-dimensions" 

const hasWindow = typeof window !== 'undefined'

const IndexPage = () => {
  const { locale, page_links } = React.useContext(LocaleContext)

  const { width } = useWindowDimensions()
  const sectionPadding = width < 1024 ? '20px 0' : '20px 0 150px 0'

  const {
    LEMONADE_PARAGRAPH_1,
    LEMONADE_PARAGRAPH_2,
    LEMONADE_PARAGRAPH_3,
    LEMONADE_PARAGRAPH_4,
  } = useTranslations()

  let galleryPadding = 0
  if(width > 1024 && width < 1599) {
    galleryPadding = 300
  } else if ( width >= 1600  && width < 2399) {
    galleryPadding = 800
  } else if ( width >= 2400 ) {
    galleryPadding = 1200
  }

  const galleryWidth = width - galleryPadding

  const container = {
    width: galleryWidth,
    height: 500,
  }

  const Gallery =
    hasWindow 
      ? ProGallery
      : ProGalleryRenderer;
  
  const options = {
    galleryLayout: 1,
    infiniteScroll: true,
    hoveringBehaviour: 'NEVER_SHOW',
  }

  return (
    <>
      <SEO title="Lucy's Lemonade" />
      <div className="section-main">
        <p ref={page_links[INDEX_PAGEKEYS_ABOUT].ref} className="lead-text">{ LEMONADE_PARAGRAPH_1 }</p>
        <p>{ LEMONADE_PARAGRAPH_2 }</p>
        <p>{ LEMONADE_PARAGRAPH_3 }</p>
        <p>{ LEMONADE_PARAGRAPH_4 }</p>

        <div ref={page_links[INDEX_PAGEKEYS_CONTACT].ref} className="button-wrap">
          <div className="border-wrap">
            <a className="button-contact" href="mailto: info@lucyslemonade.com">CONTACT</a>
          </div>
        </div>
      </div>

      <div className="section-gallery" style={{padding: sectionPadding, margin: 'auto', maxWidth: galleryWidth}}>
          <Gallery
            domId="gallery-ssr"
            items={items}
            container={container}
            isPrerenderMode={!hasWindow}
            options={options}
          />
      </div>

    </>
  )
}
export default IndexPage
